/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:ed9db3f1-bdbd-4970-b3f6-c5244dc77918",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_uSJdNW46N",
    "aws_user_pools_web_client_id": "4ooqnl6e8prajghcfs4dlbe8np",
    "oauth": {}
};


export default awsmobile;
